$tree-images-path: '~images/tree';

.table-with-tree {
    .table tr td {
        max-width: calc(100vw - 1180px);
        min-width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.tree-row {
    display: flex;
    margin: -5px;
    min-width: 94px;
}

.t-e {
    display: inline-block;
    height: 29px;
    width: 29px;
}

.t-root {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/root.svg');
}

.t-root-only {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/root-only.svg');
}

.t-b {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/branch.svg');
}

.t-cb {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/c-branch.svg');
}

.t-cn {
    display: inline-block;
    height: 29px;
    width: 29px;
    fill: #5cb85c;
    background-image: url('#{$tree-images-path}/c-node.svg');
}

.t-cn-2 {
    display: inline-block;
    height: 29px;
    width: 29px;
    fill: #5cb85c;
    background-image: url('#{$tree-images-path}/c-node-2.svg');
}

.t-cpb {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/cp-branch.svg');
}

.t-cpn {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/cp-node.svg');
}
