$break-large: 1564px;

.manage-container {
    .business-hours-container {
        @media screen and (max-width: $break-large) {
            width: 90%;
        }
    }
    .details-container {
        @media screen and (max-width: $break-large) {
            width: 83%;
        }
    }
    .hide-above-break-large {
        @media screen and (min-width: $break-large) {
            display: none;
        }
    }
    .hide-below-break-large {
        @media screen and (max-width: $break-large) {
            display: none;
        }
    }
}

.coverage-area {
    .add-county-btn {
        margin-bottom: 15px;
    }
    .Select-control {
        overflow: visible;
    }
    .add-county-text {
        margin-top: 0;
    }
    .google-maps {
        width: 680px;
        height: 400px;
        margin-top: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: $break-large) {
            width: 500px;
            height: 380px;
        }
        @media (max-width: 1270px) {
            width: 380px;
            height: 320px;
        }
    }
    .all-counties-serviced-select {
        .Select-arrow-zone {
            display: none;
        }
        .Select-clear:after {
            padding-right: 20px;
        }
    }
}

.business-hours {
    margin-top: 15px;
    margin-bottom: 15px;

    .format-hour-select {
        display: inline-block;
        width: 130px;
        vertical-align: middle;
        margin-bottom: 6px;
    }
    .day-text {
        font-size: 18px;
        font-weight: bold;
        line-height: 38px;
    }
    .to-text {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 18px;
    }
    .check-box-styling {
        display: inline-block;
        width: 100px;
        margin-top: 9px;
        margin-right: 5px;
        font-size: 16px;
    }
}

.company-details-save-btn {
    height: 45px;
    margin-bottom: 20px;
    font-size: 18px;
    i {
        font-size: 15px;
        margin-left: 7px;
    }
}

.h-divider {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 1px;
    width: 100%;
    border-top: 2px solid #dcdbdb;
}

.time-zone-dropdown {
    width: 220px;
    margin-bottom: 25px;
}

.team-page {
    .members-table {
        margin-top: 25px;
    }
    .motili-checkbox input:checked + label {
        background: none;
        border: none;
        box-shadow: none;
    }
    .motili-checkbox input + label {
        background: none;
        border: none;
        box-shadow: none;
    }
}

.edit-team-member-page {
    .after-hours-checkbox {
        margin-top: 20px;
    }
    .notifications-checkbox {
        margin-top: 25px;
    }
    .cancel-btn {
        @extend .motili-btn;
        @include button-variant($motili-light-blue, #fff, $light-gray);
        height: 45px;
        font-size: 18px;
        &:hover {
            @include button-variant($motili-light-blue, #fff, $light-gray);
        }
        &:focus {
            @include button-variant($motili-light-blue, $light-gray, $light-gray);
        }
        margin-bottom: 20px;
        margin-left: 25px;
        padding-top: 10px;
    }
}

.h-divider-edit-screen {
    margin: 25px 0 25px 0;
}
