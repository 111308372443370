//**************************************************//
//* STYLING FOR CHECKBOXES                         *//
//**************************************************//

.motili-checkbox {
    position: relative;

    label {
        display: inline;
    }

    input {
        display: none;
    }

    input + label {
        background-color: $white;
        border: 1px solid $medium-gray;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        padding: 9px;
        border-radius: 3px;
        display: inline-block;
        position: relative;
    }

    input + label:active,
    input:checked + label:active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    input:checked + label {
        background-color: $white;
        border: 1px solid $medium-gray;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
            inset 15px 10px -12px rgba(255, 255, 255, 0.1);
        color: #99a1a7;
    }

    input:checked + label:after {
        content: '\f00c';
        font-family: FontAwesome;
        //content: '\2714';
        font-size: 13px;
        position: absolute;
        top: 0px;
        left: 3px;
        color: $motili-light-blue;
    }

    span {
        position: absolute;
        left: 25px;
        top: 1px;
    }

    //input:checked + label:after {
    //  content: '\2713';
    //  font-size: 17px;
    //  position: absolute;
    //  top: -2px;
    //  left: 5px;
    //  color: #57c1e9;
    //  transform: rotate(10deg);
    //}
}

//**************************************************//
//* STYLING FOR RADIO BUTTONS                      *//
//**************************************************//

.motili-radio {
    position: relative;

    label {
        display: inline;
    }
    input {
        display: none;
    }
    input + label {
        -webkit-appearance: none;
        background-color: $white;
        border: 1px solid #cacece;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 8px;
        border-radius: 50px;
        display: inline-block;
        position: relative;
    }
    input:checked + label:after {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 50px;
        position: absolute;
        top: 3px;
        background: $motili-light-blue;
        box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
        text-shadow: none;
        left: 3px;
        font-size: 32px;
    }
    input:checked + label {
        background-color: $white;
        color: #99a1a7;
        border: 2px solid $medium-gray;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
            inset 15px 10px -12px rgba(255, 255, 255, 0.1), inset 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    input + label:active,
    input:checked + label:active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }
    span {
        position: absolute;
        left: 25px;
        top: 1px;
    }
}

//*************************************************************************//
//* STYLING FOR CUSTOM RADIO BUTTONS USED IN PREFERRED CONTRACTOR CONTACT *//
//*************************************************************************//

.preferred-contact-radio {
    label {
        border: none;
        background-color: unset;
        color: unset;
    }

    input:disabled + label {
        cursor: not-allowed;
    }

    :checked::before,
    input[type='radio'] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        clip: rect(0 0 0 0);
    }

    :checked,
    input[type='radio'] + label::before {
        content: url('../../../images/star-gold-empty.svg');
    }

    input[type='radio']:checked + label::before {
        content: url('../../../images/star-gold-filled.svg');
    }

    input[type='radio']:checked + label::after {
        display: none;
    }
}
