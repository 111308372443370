@import '../../../../../style/sass/variables/color.scss';
.busy-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    .group {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fa {
        display: flex;
        align-items: center;
    }
}
