.loading-screen {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-screen-item {
    align-self: center;
}

.loading-screen-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    text-align: center;
    cursor: progress;
    background: rgba(0, 0, 0, 0.1);
    color: #57c1e9;
    z-index: 800;
    size: 100px;
}

/* Absolute Center Spinner */
.loading-overlay {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading-overlay:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}
