//**************************************************//
//* NAVBAR                                         *//
//**************************************************//

.navbar {
    height: 50px;
    padding: 0;
}

.navbar-default {
    background-color: $dark-navy;
}

.nav > li {
    //    display: inline;
    float: left;
}

.container {
    display: flex;
}

.navbar-nav .open .dropdown-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar-default div.container {
    width: 100%;
    min-width: 1080px;
    max-width: 1440px;
    margin: auto;
}

.navbar-default .navbar-brand {
    color: $white;
}

.navbar-brand {
    padding: 8px 0px;
    margin-top: 2px;
    margin-right: 41px;
    height: 44px;
    padding-left: 27px;
}

.navigation-link {
    color: $white;
    font-size: 16px;
}

.navigation-icon-link {
    color: $white;
}

li.navigation-dropdown.dropdown a.dropdown-toggle {
    color: $white;
    font-size: 16px;
}

.navigation-dropdown.dropdown.open > ul > li > a {
    border-bottom: none;
    text-align: right;
}

.navbar-default .navbar-nav > li > a {
    color: $white;
    //display: inline;
}

.navbar-nav {
    //    display: inline;
    float: left;
    margin: 0;
    flex-direction: row;
}

.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
}

.navbar-right {
    margin-left: auto;
}

.navbar-header {
    float: left;
    height: 50px;
}

.navbar-nav > li > a {
    height: 50px;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color: $motili-light-blue;
    color: $white;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:hover {
    background-color: $motili-light-blue;
    color: $white;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: $white;
}

// the next four selectors are for disabling the active classes on navigation links
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent;
    color: $white;
}

.dropdown-toggle::after {
    content: none;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus {
    background-color: transparent;
    color: $motili-light-blue;
}

.dropdown-menu > .active > a:hover {
    color: #262626;
    background-color: #f5f5f5;
}

.navbar-default .navbar-nav > .active > a:hover {
    background-color: $motili-light-blue;
    color: $white;
}

.navbar-nav > li > .dropdown-menu {
    background-color: $white;
    font-size: 16px;
    color: $motili-light-blue;
}

li.navigation-action-dropdown.dropdown {
    background-color: $motili-light-blue;
    width: 150px;
    font-size: 16px;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: inherit;
}

.navbar-nav .open .dropdown-menu > li > a {
    line-height: 2.5;
}
//**************************************************//
//* PAGINATION                                     *//
//**************************************************//

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
    background-color: $motili-light-blue;
    border-color: $motili-light-blue;
    z-index: 0;
}

.pagination > li > a,
.pagination > li > span {
    color: $motili-light-blue;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
    color: $motili-light-blue;
}

//**************************************************//
//* TAB                                     *//
//**************************************************//

.tab-nav {
    list-style-type: none;
    border-bottom: solid;
    border-bottom-width: 2px;
    border-bottom-color: $light-gray !important;
    margin-bottom: 20px;
}

.tab-nav-item {
    display: inline;
}

.tab-nav-item-active {
    margin-bottom: -3px;
    border-bottom-width: 3px;
    border-bottom: solid;
    border-bottom-color: $motili-light-blue;
}

.footer-container {
    width: 100%;
    min-width: 1080px;
    margin: auto;
}
