$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

/* Bootstrap */
@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';

/* Font Awesome */
@import '~font-awesome/scss/font-awesome.scss';

/* React Dates */
@import '~react-dates/lib/css/_datepicker.css';

/* React-s-alert */
@import '~react-s-alert/dist/s-alert-default.css';
@import '~react-s-alert/dist/s-alert-css-effects/stackslide.css';

@import '~react-select/dist/react-select.min.css';
@import '~react-virtualized/styles.css';
@import '~react-virtualized-select/styles.css';

/* Variables */
@import './variables/color';

/* Base Motili Stylesheet */
@import './base/base';

/* Components */
@import './components/alerts';
@import './components/buttons';
@import './components/busy-spinner';
@import './components/controls';
@import './components/drop-down';
@import './components/forms';
@import './components/header';
@import './components/footer';
@import './components/orders-tables';
// @import './components/layout';
@import './components/loading-screen';
@import './components/navigation';
@import './components/tables';
@import './components/tables-DEPRECATED';
@import './components/tree';

/* Pages */
@import './pages/login';
@import './pages/manage';
@import './pages/contractorCompanyDetails';

/* Motili-UI */
@import '~motili-ui/dist/assets/styles/thirdPartyStyles.css';
